<template>
  <b-container id="surgeries-filter" fluid class="pt-3 pb-3">
    <b-row>
      <b-col cols="3" class="mr-3 pd-0">
        <b-form-group class="search-container">
          <b-form-input
            v-model="filters.search"
            placeholder="Pesquisar por procedimento ou paciente"
            debounce="500"
          />
          <SearchIcon class="icon"/>
        </b-form-group>
      </b-col>
      <b-col cols="2" class="pd-0">
        <b-form-group>
          <DatabaseCheckboxSelect
						id="rooms"
						type="rooms"
						label="Todas as salas"
						placeholder="Pesquisar"
            @onChange="changeFilter($event, 'rooms')"
					/>
        </b-form-group>
      </b-col>
      <b-col cols="3" class="pd-0">
        <b-form-group>
          <DatabaseCheckboxSelect
						id="professional"
						type="professionals"
						label="Todos profissionais"
						placeholder="Pesquisar"
            @onChange="changeFilter($event, 'professionals')"
					/>
        </b-form-group>
      </b-col>
      <b-col cols="3" class="pd-0">
        <Periods @select="handlePeriod" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { getSurgeryStatus } from '@/utils/surgeryCenterHelper'

export default {
  components: {
    SearchIcon: () => import('@/assets/icons/search.svg'),
    DatabaseCheckboxSelect: () => import('@/components/DatabaseCheckboxSelect.vue'),
    Periods: () => import('@/components/General/Periods.vue'),
  },

  props: {
    withPeriod: Boolean
  },

  data () {
    return {
      filters: {
        period: {
          start: null,
          end: null,
        },
      },
    }
  },

  computed: {
    selectedRooms: function () { return this.rooms },
  },

  methods: {
    getSurgeryStatus,
    handlePeriod(value) {
      this.$set(this.filters.period, 'start', value.start)
      this.$set(this.filters.period, 'end', value.end)
    },
    changeLabeledFilter (value, filter) {
      const options = value ? value.map(option => option.value) : null
      this.$set(this.filters, filter, options)
    },
    changeFilter (value, filter) {
      if(value?.length) this.$set(this.filters, filter, value)
    },
  },

  watch: {
    filters: {
      handler(newValue) {
        this.$emit('change', newValue)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.w-24 {
    max-width: 24%;
    flex: 0 0 24%;
}
#surgeries-filter {
  padding: 0;
  .icon {
    width: 24px;
    height: 24px;
  }

  .search-container {
    input {
      position: absolute;
      padding-left: 35px;
    }
    svg {
      position: relative;
      margin: 6px 0 0 6px;
      stroke: var(--neutral-500);
    }
  }
}
</style>